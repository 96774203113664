/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   url: "/apps/email",
  //   name: "Email",
  //   slug: "email",
  //   icon: "MailIcon",
  //   i18n: "Email",
  // },
  {
    url: '/proveedor/inicio',
    name: 'Inicio',
    icon: 'HomeIcon',
  },
  {
    header: 'Proyectos',
    icon: 'PackageIcon',
    items: [
      {
        url: '/proveedor/crear-proyecto/nuevo',
        name: 'Crear proyecto',
        slug: 'crearProyecto',
        icon: 'PlusCircleIcon',
      },
      {
        url: '/proveedor/simulador-express',
        name: 'Simulador express',
        slug: 'simuladorExpress',
        icon: 'DatabaseIcon',
      },
      {
        url: '/proveedor/mis-proyectos',
        name: 'Mis proyectos',
        slug: 'misProyectos',
        icon: 'BriefcaseIcon',
      },
      {
        url: '/proveedor/mis-proyectos-archivados',
        name: 'Archivados',
        slug: 'misProyectosArchivados',
        icon: 'ArchiveIcon',
      },
      {
        url: '/proveedor/mis-herramientas',
        name: 'Mis herramientas',
        slug: 'misHerramientas',
        icon: 'AwardIcon',
      }
    ]
  },
  {
    header: "Programas",
    icon: "",
    condition: "isProgramSupplier",
    items: [
      {
        url: '/proveedor/mis-prospectos',
        name: 'Coca-Cola FEMSA',
        slug: 'misProspectos',
        icon: 'UsersIcon',
        condition: 'isProgramSupplier',
        step: 'v-step-5'
      },
    ]
  },
  // {
  //   header: 'Herramientas',
  //   icon: 'PackageIcon',
  //   items: [
  //     {
  //       url: '/proveedor/mis-herramientas',
  //       name: 'Mis herramientas',
  //       slug: 'misHerramientas',
  //       icon: 'AwardIcon',
  //     },
  //     {
  //       url: '/proveedor/simulador-express',
  //       name: 'Simulador express',
  //       slug: 'simuladorExpress',
  //       icon: 'DatabaseIcon',
  //     },
  //     // {
  //     //   url: '/simulador-detallado',
  //     //   name: 'Simulador de pagos',
  //     //   slug: 'simuladorDetallado',
  //     //   icon: 'LayersIcon',
  //     // },    
  //   ]
  // },
  {
    header: 'Finanzas',
    icon: 'PackageIcon',
    items: [
      {
        url: '/proveedor/pagos',
        name: 'Mis pagos',
        slug: 'misPagos',
        icon: 'CreditCardIcon',
      },      
    ]
  },
]

