/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          items   => items of current item (current item will become dropdown )
                NOTE: items don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
    // {
    //   url: "/apps/email",
    //   name: "Email",
    //   slug: "email",
    //   icon: "MailIcon",
    //   i18n: "Email",
    // },
    {
      url: '/proveedor/inicio',
      name: 'Inicio',
      icon: 'HomeIcon',
      step: 'v-step-0'
    },
    {
      url: '/proveedor/onboarding/completar-perfil',
      name: 'Completar perfil',
      icon: 'UserIcon',
      step: ''
    },
    {
      url: '/proveedor/simulador-express',
      name: 'Simulador express',
      icon: 'DatabaseIcon',
      step: ''
    },
    {
      header: 'CRÉDITO PARA CLIENTES',
      icon: 'PackageIcon',
      isOpen: true,
      items: [
        {
          url: '/proveedor/crear-proyecto/nuevo',
          name: 'Crear proyecto',
          slug: 'crearProyecto 1111',
          icon: 'PlusCircleIcon',
          step: 'v-step-3',
          condition: 'supplier_status_3_or_more',
        },
        {
          url: '/proveedor/mis-proyectos',
          name: 'Listado de proyectos',
          slug: 'misProyectos',
          icon: 'BriefcaseIcon',
          step: 'v-step-4'
        }
      ]
    },
    {
      header: 'Herramientas',
      icon: 'PackageIcon',
      condition: '',
      isOpen: true,
      items: [
        {
          url: '/proveedor/mis-herramientas',
          name: 'Otras herramientas',
          slug: 'misHerramientas',
          icon: 'BookIcon',
          condition: '',
          step: 'v-step-7'
        },
      ]
    }
  ]
  
  