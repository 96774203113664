/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          items   => items of current item (current item will become dropdown )
                NOTE: items don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   url: "/apps/email",
  //   name: "Email",
  //   slug: "email",
  //   icon: "MailIcon",
  //   i18n: "Email",
  // },
  {
    url: '/proveedor/inicio',
    name: 'Inicio',
    icon: 'HomeIcon',
    step: 'v-step-0'
  },
  {
    header: "Mi Perfil",
    icon: 'UserIcon',
    condition: 'supplier_status_less_or_3',
    isOpen: false,
    items: [
      {
        url: '/proveedor/perfil/editar',
        name: 'Perfil',
        slug: 'perfil',
        icon: 'UserIcon',
        dynamicTagCondition: 'always',
        dynamicTagLabel: 'check_supplier_profile_status',
        dynamicTagColor: 'check_supplier_profile_status_color',
        condition: 'show_pending_profile',
        step: 'v-step-1',
        needPulse: false
      },
      // {
      //   url: '/proveedor/perfil/editar',
      //   name: 'Actualizar',
      //   slug: 'perfil-editar',
      //   icon: 'EditIcon',
      //   condition: 'supplier_status_less_or_3',
      //   step: 'v-step-2'
      // },
    ]
  },
  // {
  //   url: '/proveedor/impacta-mas',
  //   name: 'ImpactaMás',
  //   icon: 'AwardIcon',
  //   // dynamicTagCondition: 'always',
  //   // dynamicTagLabel: 'new_impacta_mas_chip',
  //   // dynamicTagColor: 'new_impacta_mas_chip_color',
  //   condition: 'supplier_status_4_or_more',
  //   step: 'v-step-00'
  // },
  // {
  //   header: 'Crédito para integradores',
  //   icon: 'PackageIcon',
  //   condition: '',
  //   isOpen: false,
  //   items: [
  //     {
  //       url: '/proveedor/credito-integradores/simulador',
  //       name: 'Simulador',
  //       slug: "capitalWorkSimulator",
  //       icon: 'DatabaseIcon',
  //       condition: '',
  //       step: 'v-step-6'
  //     },
  //     {
  //       url: '/proveedor/credito-integradores',
  //       name: 'Conoce más',
  //       slug: 'integradorHerramientas',
  //       icon: 'BookIcon',
  //       needPulse: false,
  //       step: 'v-step-6'
  //     }
  //   ]
  // },
  {
    header: 'CRÉDITO PARA CLIENTES',
    icon: 'PackageIcon',
    condition: 'supplier_status_3_or_more',
    isOpen: true,
    items: [
      {
        url: '/proveedor/crear-proyecto/nuevo',
        name: 'Crear proyecto',
        slug: 'crearProyecto',
        icon: 'PlusCircleIcon',
        condition: 'supplier_status_3_or_more',
        step: 'v-step-3'
      },
      {
        url: '/proveedor/simulador-express',
        name: 'Simulador express',
        slug: 'simuladorExpress',
        icon: 'DatabaseIcon',
        condition: '',
        step: 'v-step-6'
      },
      {
        url: '/proveedor/mis-proyectos',
        name: 'Listado de proyectos',
        slug: 'misProyectos',
        icon: 'BriefcaseIcon',
        condition: 'supplier_status_3_or_more',
        step: 'v-step-4'
      },
      {
        url: '/proveedor/mis-proyectos-archivados',
        name: 'Archivados',
        slug: 'misProyectosArchivados',
        icon: 'ArchiveIcon',
        condition: 'never',
        step: 'v-step-5'
      },
      {
        url: '/proveedor/mis-herramientas',
        name: 'Otras herramientas',
        slug: 'misHerramientas',
        icon: 'BookIcon',
        condition: '',
        step: 'v-step-7'
      }
    ]
  },
  {
    header: "Programas",
    icon: "",
    condition: "isProgramSupplier",
    isOpen: false,
    items: [
      {
        url: '/proveedor/mis-prospectos',
        name: 'Coca-Cola FEMSA',
        slug: 'misProspectos',
        icon: 'UsersIcon',
        dynamicTagCondition: 'need_to_show_program_notes_to_read',
        dynamicTagLabel: 'program_notes_to_read',
        dynamicTagColor: 'new_impacta_mas_chip_color',
        condition: 'isProgramSupplier',
        needPulse: true,
        step: ''
      },
    ]
  },
  // {
  //   header: 'Herramientas',
  //   icon: 'PackageIcon',
  //   condition: '',
  //   isOpen: false,
  //   items: [
  //     {
  //       url: '/proveedor/simulador-express',
  //       name: 'Simulador express',
  //       slug: 'simuladorExpress',
  //       icon: 'DatabaseIcon',
  //       condition: '',
  //       step: 'v-step-6'
  //     },
  //     // {
  //     //   url: '/simulador-detallado',
  //     //   name: 'Simulador detallado',
  //     //   slug: 'simuladorDetallado',
  //     //   icon: 'LayersIcon',
  //     //   condition: 'supplier_status_4_or_more',
  //     // },
  //     {
  //       url: '/proveedor/mis-herramientas',
  //       name: 'Otras herramientas',
  //       slug: 'misHerramientas',
  //       icon: 'BookIcon',
  //       condition: '',
  //       step: 'v-step-7'
  //     },
  //   ]
  // },
  {
    header: 'Finanzas',
    icon: 'PackageIcon',
    condition: 'supplier_status_4_or_more',
    isOpen: false,
    items: [
      {
        url: '/proveedor/pagos',
        name: 'Mis pagos',
        slug: 'misPagos',
        icon: 'CreditCardIcon',
        condition: 'supplier_status_4_or_more',
        step: 'v-step-8'
      },
    ]
  },
  {
    header: 'Configuración',
    icon: 'SettingsIcon',
    condition: 'supplier_status_4_or_more',
    isOpen: false,
    items: [
      {
        url: '/proveedor/vendedores',
        name: 'Mis vendedores',
        slug: 'vendedores',
        icon: 'PocketIcon',
        condition: 'supplier_status_4_or_more',
        step: 'v-step-9'
      },
      {
        url: '/proveedor/solicitantes',
        name: 'Mis solicitantes',
        slug: 'solicitantes',
        icon: 'UsersIcon',
        condition: 'supplier_status_4_or_more',
        step: 'v-step-10'
      },
      {
        url: '/proveedor/acreditaciones',
        name: 'Mis tecnologías',
        slug: 'acreditaciones',
        icon: 'AwardIcon',
        condition: 'supplier_status_4_or_more',
        step: 'v-step-11'
      },
      {
        url: '/proveedor/catalogo',
        name: 'Catalogo de productos',
        slug: 'catalogo',
        icon: 'PackageIcon',
        condition: 'supplier_status_4_or_more',
        step: 'v-step-12'
      },
    ]
  },

]

